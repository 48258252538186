import React from 'react';
import './CurvedArrow.scss';

function CurvedArrow() {
  return (
    <svg
      className="curved-arrow"
      xmlns="http://www.w3.org/2000/svg"
      width="134.766"
      height="137.265"
      viewBox="0 0 134.766 137.265"
    >
      <g id="curved-arrow" transform="translate(1.793 3)">
        <path
          id="Path_3"
          d="M137.675 39.083c-5.247-12.641-13.834-23.613-17.889-36.97-.716-2.147-4.293-3.1-5.247-.716-5.724 13.357-14.311 25.521-18.366 39.594-.716 2.624 2.862 5.247 4.77 2.862 2.862-3.578 6.2-6.44 9.54-9.779-.477 20.513 0 41.025-3.578 61.3C105 106.345 102.374 118.749 94.5 127.1c-9.54 10.018-21.467.954-26.714-8.348-4.532-8.11-12.4-43.172-28.145-31.008-9.3 7.156-11.687 16.935-14.55 27.668A33.841 33.841 0 0 1 21.755 124c-5.486 10.256-12.641-6.44-12.88-10.495-.477-8.11 1.908-16.458 3.816-24.329.716-2.385-2.862-3.339-3.816-.954-4.293 11.21-10.256 30.292 0 40.071 9.064 8.348 16.7 2.863 20.274-6.917 2.385-6.44 11.449-42.217 21.944-25.521 6.917 10.971 8.825 24.09 17.173 34.347 5.963 7.155 16.7 8.825 25.044 5.247 10.018-4.532 14.311-17.889 16.935-27.43 6.44-23.852 6.44-49.612 5.724-74.179a92.35 92.35 0 0 0 18.6 9.779c2.631.712 4.061-2.628 3.106-4.536zM106.668 30.5c3.1-6.679 6.917-13.357 10.256-20.036 3.578 8.348 8.11 15.742 12.4 23.852-5.009-3.1-9.779-6.679-15.265-7.394a2.4 2.4 0 0 0-2.624 1.431 9.6 9.6 0 0 0-4.767 2.147z"
          className="cls-1"
          data-name="Path 3"
          transform="translate(-5 -3)"
        />
      </g>
    </svg>
  );
}

export default CurvedArrow;
